import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
import Routes from './router';
import AppProvider from './AppProvider';
import moment from 'moment';
import { getAccountData } from "@iso/components/library/helpers/local_storage";
import 'moment/locale/en-gb';
import 'moment/locale/es';

const dataAccount = getAccountData();
const language = (dataAccount && dataAccount.defaultLanguage) ? dataAccount.defaultLanguage : 'english';
if (language === "spanish") {
    moment.locale('es');
} else {
    moment.locale('en');
}

const App = () => (
  <Provider store={store}>
    <AppProvider>
      <>
        <GlobalStyles />
        <Routes />
      </>
    </AppProvider>
  </Provider>
);

export default App;