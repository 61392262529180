import { all, takeEvery, put, call, takeLatest } from 'redux-saga/effects';
import { newInvoice } from '../../pages/Invoice/config';
import actions from './actions';
import actionSerie from '../serie/actions';
import omit from 'lodash/omit';
import { simpleQueryFirestoreWithFilter, simpleQueryFirestoreWithFilterOrderBy } from '../../components/utility/firestore.helper';
import { rsf } from '@iso/lib/firebase/firebase';
import clone from 'clone';
import { getAccountIdPrefix } from '@iso/components/library/helpers/local_storage';
import {setInvoiceNumber} from "../../components/library/helpers/invoice_number";
import {invoiceSeries} from "../../config/site.config";
import {  stringToPosetiveInt,updateValues } from "../../pages/Invoice/components/helpers";

const COLLECTION_NAME = 'invoices';
const COLLECTION_NAME_PROP = 'properties';
const COLLECTION_NAME_CLIENT = 'users';

export function* getInvoice(query) {
  try {
    const filterInvoice = { 'budget':  {'value':  query.payload.id, 'operator': '==', 'field': 'id' }};
    const dataInvoice = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME, filterInvoice);
    console.log("dataInvoice");
    console.log(dataInvoice);
    const dataSourceInvoice = [];
    Object.keys(dataInvoice).map((item, index) => {
      return dataSourceInvoice.push({
        ...dataInvoice[item],
        key: item,
      });
    });
    console.log("dataSourceInvoice");
    console.log(dataSourceInvoice);
    yield put(actions.getInvoiceSuccess(dataSourceInvoice[0]));
  } catch (error) {
    yield put(actions.getInvoiceError(error));
  }
}

export function* getInvoices({ payload }) {
  try {
    const {filter} = payload;
    let filters = [];
    let orderBy = { field: "orderDate", type: "desc" };
    if (filter !==undefined){
      if (filter.type){
        filters.push({field: 'type', operator: '==', value : filter.type});
      }
      if (filter.startDate){
        filters.push({field: 'orderDate', operator: '>=', value : filter.startDate.valueOf()});
      }
      if (filter.endDate){
        filters.push({field: 'orderDate', operator: '<=', value : filter.endDate.valueOf()});
      }
      if (filter.billToId){
        filters.push({field: 'billToId', operator: '==', value : filter.billToId});
      }
      if (filter.idOwner){
        filters.push({field: 'idOwner', operator: '==', value : filter.idOwner});
      }
      if (filter.statusPayment){
        filters.push({field: 'statusPayment', operator: '==', value : filter.statusPayment});
      }
      if (filter.invoice_serie){
        filters.push({field: 'invoice_serie', operator: '==', value : filter.invoice_serie});
      }
    }

    filters.push({field: 'deleted_at', operator: '==', value : null});
    let data = yield simpleQueryFirestoreWithFilterOrderBy(getAccountIdPrefix() + COLLECTION_NAME, filters, orderBy);
    //yield put(actions.loadFromFireStoreSuccess(data));
    const dataSource = [];
    Object.keys(data).map((item, index) => {
      return dataSource.push({
        ...data[item],
        key: item,
      });
    });
    yield put({
      type: actions.GET_INVOICES_SUCCESS,
      invoices: dataSource
    });
  } catch (error) {
    console.log(error);
  }
}

function getStatusBudget(itemsSelected, itemTot)
{
  let status='non_invoice';
  if (itemsSelected === 0) {
      status='non_invoice';
  } else if (itemsSelected < itemTot) {
      status='partial_invoice';
  } else if (itemsSelected === itemTot) {
      status='invoices';
  }
  return status;
}



function* deleteItemsBudgetFromInvoice(invoice){
  let data = invoice.invoiceList.filter(row => row.budgetNumber != null);//Selecciono todos los item del booking a borrar que tenga un budget en relacion
  for (let i = 0;  i < data.length; i ++) {
      let filterInvoice = { 'invoice':  {'value':  data[i].budgetNumber, 'operator': '==', 'field': 'id' }};
      let dataBudget = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME, filterInvoice);//Selecciono el budget
      if(dataBudget){
        const dataSource = [];
        Object.keys(dataBudget).map((item, index) => {
          return dataSource.push({
            ...dataBudget[item],
            key: item,
          });
        });//Cargo del budget en un objeto
        //Selecciono todos los items en cheked menos lo que tiene id invoice que se elimino
        let itemsSelected=dataSource[0].invoiceList.filter(row => (row.checked===true && row.invoiceNumber !== invoice.id)).length;
        let itemTot=dataSource[0].invoiceList.length;
       
        //Vuelvo a verificar el estado del bugdet
       
        const statusBudget = yield call(getStatusBudget, itemsSelected, itemTot);
    

        let newBudget = {
          ...dataSource[0],
          orderStatus:statusBudget,
          //Vuelvo a cargar los items del budget con items que NO tenga el numero de invoice que elimino
          invoiceList:dataSource[0].invoiceList.filter(row => (row.invoiceNumber !== invoice.id) )
        };
        //Actualizo el budget
        yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME}/${newBudget.key}`, {
          ...omit(newBudget, ['key']),
        });
      }
  }
}

export function* updateInvoiceSaga({ payload }) {
  const { invoice, actionName, filter } = payload;
  try {
    switch (actionName) {
      case 'delete':
        yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME}/${invoice.key}`, {
          deleted_at: new Date().getTime(),
        });
        /* start delete payment related */
        let filters = [{ field: 'invoice', operator: '==', value: invoice.id }];
        let data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + 'payments', filters);
        //Verifico si el tipo de a borrar es invoice y que tenga items con un budget relacionado
        if(invoice.type === 'invoice' && invoice.invoiceList.filter(row => row.budgetNumber != null).length>0){
            yield call(deleteItemsBudgetFromInvoice, invoice);
        }  
        if (data) {
          let paymentId = Object.keys(data);
          yield call(rsf.firestore.setDocument, getAccountIdPrefix() + `payments/${paymentId[0]}`, {
            deleted_at: new Date().getTime(),
          });
        }
        /* end delete payment*/
        break;
      case 'update':

        if (invoice.type === 'budget' && invoice.invoiceList.filter(row => (row.checked === true) && (row.invoiceNumber==null) ).length > 0 ) {
          let newInvoiceNumber=new Date().getTime()+1;
          let updateInvoiceList = invoice.invoiceList.map(item => {
            if ((item.checked === true) && (item.invoiceNumber==null)) {
              return { ...item, invoiceNumber: newInvoiceNumber, budgetNumber:invoice.id };
            }
            return item; 
          });



          invoice.invoiceList=updateInvoiceList;

          let statusBudget = yield call(getStatusBudget, invoice.invoiceList.filter(row => row.checked === true && row.invoiceNumber).length, invoice.invoiceList.length);
          invoice.orderStatus=statusBudget;

          yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME}/${invoice.key}`, {
            ...omit(invoice, ['key']),
          });

          let totalCost=invoice.invoiceList.filter(row => row.checked === true && row.invoiceNumber === newInvoiceNumber).reduce((sum, row) => sum + Number(row.price), 0);
          
          let newInvoice = {
            ...invoice,
            type: 'invoice',
            orderStatus: 'draft',
            invoice_serie: `${invoiceSeries.normal.id}`,//Para los invoice es uno
            id: newInvoiceNumber.toString(), // Generar un nuevo ID único para la factura
            number: `#${newInvoiceNumber}`, // Generar un nuevo número de factura
            orderDate: new Date().getTime(), // Usar la fecha y hora actual
            invoiceList: invoice.invoiceList.filter(row => row.checked === true && row.invoiceNumber===newInvoiceNumber),
            totalCost: totalCost,
            subTotal:totalCost
          }; 
          newInvoice = updateValues(newInvoice);
          

          yield call(rsf.firestore.addDocument, getAccountIdPrefix() + COLLECTION_NAME, newInvoice);
        } else {

          if (invoice.type === 'budget' ) {
           let statusBudget = yield call(getStatusBudget, invoice.invoiceList.filter(row => row.checked === true && row.invoiceNumber).length, invoice.invoiceList.length);
           invoice.orderStatus=statusBudget;
          }
          yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME}/${invoice.key}`, {
            ...omit(invoice, ['key']),
          });
        }
        break;
      default:

        if (invoice.type === 'budget' && invoice.invoiceList.length > 0 ) {

          let newInvoiceNumber=new Date().getTime()+1;
          let updateInvoiceList = invoice.invoiceList.map(item => {
            if (item.checked === true) {
              return { ...item, invoiceNumber: newInvoiceNumber, budgetNumber:invoice.id };
            }
            return item; // Si no, se devuelve el objeto sin cambios
          });
          invoice.invoiceList=updateInvoiceList;
          //Se guarda el budget en la siguiente linea con los items seleccionados y el nuevo numero de invoice para que se relacionen
          yield call(rsf.firestore.addDocument, getAccountIdPrefix() + COLLECTION_NAME, invoice); 

          let totalCost=invoice.invoiceList.filter(row => row.checked === true && row.invoiceNumber===newInvoiceNumber).reduce((sum, row) => sum + Number(row.price), 0);

          let newInvoice = {
            ...invoice,
            type: 'invoice',
            orderStatus: 'draft',
            invoice_serie: `${invoiceSeries.normal.id}`,
            id: newInvoiceNumber.toString(), // Generar un nuevo ID único para la factura
            number: `#${newInvoiceNumber}`, // Generar un nuevo número de factura
            orderDate: new Date().getTime(), // Usar la fecha y hora actual
            invoiceList:invoice.invoiceList.filter(row => row.checked === true),
            taxable:totalCost,
            totalCost:totalCost,
            subTotal:totalCost
          };
          newInvoice = updateValues(newInvoice);
          yield call(rsf.firestore.addDocument, getAccountIdPrefix() + COLLECTION_NAME, newInvoice);
        }else
        {
          yield call(rsf.firestore.addDocument, getAccountIdPrefix() + COLLECTION_NAME, invoice);
        }
        break;
    }
    yield put({ type: actions.UPDATE_INVOICE_SUCCESS });
    yield put({ type: actions.GET_INVOICES, payload: { filter } });
  } catch (error) {
    console.log(error);
  }
}



export function* updateInvoicesBulk({ payload }) {
  const getYearFromTimestamp = (d) => {
    let date = new Date(d);
    return date.getFullYear().toString();
  }
  const { invoices, series, filter } = payload;
  try {
    let objectSeries = Object.keys(series);
    for (let i = 0; objectSeries.length > i ; i++){
      for (let y = 0; invoices.length > y; y++){
        if (!invoices[y].invoice_serie || invoices[y].invoice_serie === "0")
          invoices[y].invoice_serie = (invoices[y].type === "invoice") ? "1" : "101";
        if (String(series[objectSeries[i]].id) === invoices[y].invoice_serie && invoices[y].orderStatus === 'draft' ){
          let found = false;
          let year = getYearFromTimestamp(invoices[y].orderDate);
          if (series[objectSeries[i]].years !== undefined) {
            Object.keys(series[objectSeries[i]].years).forEach((y) => {
              if (year === y){
                found = true;
                series[objectSeries[i]].years[y]++;
              }
            });
          }else{
            series[objectSeries[i]].years = {};
          }
          if(!found){
            series[objectSeries[i]].years[year] = 1;
          }
          yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME}/${invoices[y].key}`, {
            ...omit(invoices[y], ['key']),
            orderStatus: invoices[y].type,
            number: setInvoiceNumber(series[objectSeries[i]], year, false)
          });
          let filters = [{field: 'invoice', operator: '==', value : invoices[y].id}];
          let data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + 'payments', filters);
          if (data){
            let paymentId = Object.keys(data);
            yield call(rsf.firestore.setDocument, getAccountIdPrefix() + `payments/${paymentId[0]}`, {
              ...omit(data[paymentId[0]], ['description']),
              description: invoices[y].type +" #" + setInvoiceNumber(series[objectSeries[i]], year, false),
            });
          }
        }
      }
      yield put({type: actionSerie.SAVE_INTO_FIRESTORE, payload: {data: { key: objectSeries[i], ...series[objectSeries[i]] }, actionName: 'update' }});
    }
    yield put({ type: actions.GET_INVOICES, payload: {filter}});
  } catch (error) {
    console.log(error);
  }
}

export function* deleteUpdatePaymentFix(){
  let data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME, []);
  const dataSource = [];
  Object.keys(data).map((item, index) => {
    return dataSource.push({
      ...data[item],
      key: item,
    });
  });
  for (let i = 0; i < dataSource.length; i++){
    if (dataSource[i].id && dataSource[i].invoice_serie !=='7' && dataSource[i].invoice_serie !=='8'){
      let filters = [{field: 'invoice', operator: '==', value : dataSource[i].id}];
      let data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + 'payments', filters);
      if (data && Object.keys(data).length !== 0){
        let paymentId = Object.keys(data);
        yield call(rsf.firestore.setDocument, getAccountIdPrefix() + `payments/${paymentId[0]}`, {
          ...omit(data[paymentId[0]], ['description']),
          description: dataSource[i].type +" #" + dataSource[i].number,
        });
      }
    }else if (dataSource[i].id && (dataSource[i].invoice_serie ==='7' || dataSource[i].invoice_serie ==='8')){
      let filters = [{field: 'invoice', operator: '==', value : dataSource[i].id}];
      let data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + 'payments', filters);
      if (data && Object.keys(data).length !== 0){
        let paymentId = Object.keys(data);
        yield call(rsf.firestore.setDocument, getAccountIdPrefix() + `payments/${paymentId[0]}`, {
          ...data[paymentId[0]],
          deleted_at: new Date().getTime(),
        });
      }
    }
  }
}


export function* updateInvoiceItemBooking({payload}) {
  const { booking, items } = payload;
  const getTotal = (itemList) => {
    let total = 0;
    itemList.map(i => {
      total+= parseFloat(i.costs);
      return true;
    });
    return total;
  }
  const checkDeletedItem = (item, itemList ,property) => {
    let deleted = false;
    if (item.propertyId === property[0].id) {
      deleted = true;
    }
    itemList.map(i => {
      if (item.createdAt === i.createdAt && item.itemName === i.itemName){
        deleted = false;
      }
      return true;
    });
    return deleted;
  }
  const getItems = (itemList , item, property) => {
    let cont = 1;
    let itemsAux = itemList
    .filter((i, index) => {
      if ((i.createdAt === item.createdAt && i.itemName === item.itemName) || checkDeletedItem(i, items, property)){
        return false;
      }
      return true;
    })
    .map( (i, index) => {
        i.key = cont;
        cont++;
        return i;
      });
    itemsAux.push({
      key: cont,
      itemName: item.itemName,
      itemType: 'T',
      noTaxable: item.invoice,
      costs: item.costs,
      qty: item.qty,
      price: item.price,
      propertyId: property[0].id,
      createdAt: item.createdAt
    });
    return itemsAux;
  }
  try {
    for (let i = 0; i < items.length; i++ ){
      let item = items[i];
      let filtersProp = [];
      let filtersClient = [];
      let filters = [];
      let total = 0;
      filtersProp.push({ field: 'id', operator: '==', value : booking.property_id });
      let dataProperty = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME_PROP, filtersProp);
      let property = Object.keys(dataProperty).map((i, index) => {
        return {
          ...dataProperty[i],
          key: i,
        };
      });
      filtersClient.push({ field: 'id', operator: '==', value : property[0].ownerId });
      let dataClient = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME_CLIENT, filtersClient);
      let client = Object.keys(dataClient).map((i, index) => {
        return {
          ...dataClient[i],
          key: i,
        };
      });
      let itemDate = new Date(item.createdAt);
      let itemType = (item.invoice) ? 'invoice': 'note';
      /* CRONBIT TO-DO obtener mes y año del item y comparar para no meterlo en invoices antiguos*/
      filters.push({field: 'orderDate', operator: '<=', value : item.createdAt.valueOf()});
      filters.push({field: 'orderMonth', operator: '==', value : itemDate.getMonth()});
      filters.push({field: 'billToId', operator: '==', value : property[0].ownerId});
      filters.push({field: 'type', operator: '==', value : itemType });

      let data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME, filters);
      if (Object.entries(data).length === 0){
        let invoice = clone(newInvoice);
        let orderDate = new Date(itemDate.getFullYear(), itemDate.getMonth()+1, 0);
        let newId = new Date().getTime();
        invoice.id = `${newId}`;
        invoice.number = `#${newId}`;
        invoice.orderStatus = 'draft';
        invoice.orderDate = orderDate.valueOf();
        invoice.orderMonth = itemDate.getMonth();
        invoice.billTo = client[0].business_name;
        invoice.billToId = property[0].ownerId;
        invoice.invoiceProperty = property[0].id;
        invoice.propertyName = property[0].name;
        invoice.invoiceList.pop();
        invoice.invoiceList = getItems(invoice.invoiceList, item, property);
        invoice.totalCost = parseFloat(item.price)+ parseFloat(item.price*invoice.vatRate/100);
        invoice.subTotal = item.price;
        invoice.taxable = item.price;
        invoice.vatPrice = item.price*invoice.vatRate/100;
        invoice.type = itemType;
        yield put({ type: actions.UPDATE_INVOICE, payload: {invoice: invoice , actionName: 'insert'} });
      }else{
        let invoice = Object.keys(data).map((item, index) => {
          return {
            ...data[item],
            key: item,
          };
        });
        invoice = invoice[0];
        invoice.invoiceList = getItems(invoice.invoiceList, item, property);
        total = getTotal(invoice.invoiceList);
        invoice.totalCost = parseFloat(total)+ parseFloat(total*invoice.vatRate/100);
        invoice.subTotal = total;
        invoice.taxable = total;
        invoice.vatPrice = total*invoice.vatRate/100;
        yield put({ type: actions.UPDATE_INVOICE, payload: {invoice: invoice , actionName: 'update'} });
      }
    }
  } catch(error){
      console.log(error);
  }
  return true;
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_INVOICE, getInvoice),
    takeLatest(actions.GET_INVOICES, getInvoices),
    takeEvery(actions.UPDATE_INVOICE, updateInvoiceSaga),
    takeEvery(actions.UPDATE_INVOICE_ITEM_BOOKING, updateInvoiceItemBooking),
    takeEvery(actions.UPDATE_INVOICES_BULK, updateInvoicesBulk),
    takeEvery(actions.FIX_PAYMENTS_INVOICE, deleteUpdatePaymentFix)
  ]);
}
