import $ from 'jquery';

export const zeroPad = (num, places) => {
    return String(num).padStart(places, "0");
}

export const updateValues = (invoice) => {
    const { invoiceList } = invoice;
    console.log(invoiceList)
    let subTotal = 0;
    let taxable = 0;
    invoiceList.forEach((item, index) => {
      const price = (item.costs) ? item.costs * item.qty :  0 ;
      invoice.invoiceList[index].price = parseFloat(price).toFixed(2);
      subTotal += price;
      if (!invoice.invoiceList[index].noTaxable) {
        taxable += price;
      }
    });
    invoice.invoiceList = [...invoiceList];
    invoice.taxable = parseFloat(taxable).toFixed(2);
    invoice.subTotal = parseFloat(subTotal).toFixed(2);
    invoice.vatPrice = parseFloat(
      parseFloat(invoice.vatRate) * parseFloat(taxable) * 0.01
    ).toFixed(2);
    invoice.totalCost = (
      parseFloat(invoice.vatPrice) + parseFloat(subTotal)
    ).toFixed(2);
    return invoice;
};

export function initSelectAutoExpand () {
  $(document)
  .one('focus.autoExpand', 'textarea.autoExpand', function(){
      let savedValue = this.value;
      this.value = '';
      this.baseScrollHeight = this.scrollHeight;
      this.value = savedValue;
  })
  .on('input.autoExpand', 'textarea.autoExpand', function(){
      let minRows = this.getAttribute('data-min-rows')|0, rows;
      this.rows = minRows;
      rows = Math.ceil((this.scrollHeight - this.baseScrollHeight) / 16);
      this.rows = minRows + rows;
  });
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  } else if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}
export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}

export function stringToFloat(value, defValue = 0) {
  return parseFloat(value).toFixed(2);
}

export function stringToPosetiveFloat(value, defValue = 0) {
  const val = stringToFloat(value, defValue);
  return val > -1 ? val : defValue;
}